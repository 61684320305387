/*
 * main class for all media
 *
 * should include:
 *  - images
 *  - figures and figcaption
 *  - video
 *  - audio
 */
.a-media {
}

.a-media-block {
    display: block;
    margin: 0 auto;
}

.a-media-blog {
    height: 250px;
}

.a-media-responsive {
    width: 100%;
}

@include media-breakpoint-up("md") {
    // if we are on viewport larger than tablet
    // images are set to their native size
    // but never exceeding 100% of parent
    .a-media-responsive {
        width: auto;
        max-width: 100%;
    }
}

// currently this file is a mess (from here)
// @todo cleanup all atoms within _a-media
// - absolute sizing is in the wrong place
// - margins and paddings don't belong here
// - do style these attributes through molecule
// - we do mobile first so breakpoints must be addressed <method>-up
// - must be traded off for each case

// logo
.a-nav-brand {
    background-image: $BG-Logo;
    background-repeat: no-repeat;;
    width: 270px;
    height: 70px;
    margin-top: -16px;
    display: block;
}

.a-nav-brand-nosearch {
    background-image: $BG-Logo;
    background-repeat: no-repeat;;
    width: 270px;
    height: 70px;
    margin-top: 0;
    display: block;
}

.o-header-new {
    .a-nav-brand {
        background-image: $BG-Logo-Inverse;
        background-repeat: no-repeat;;
        width: 270px;
        height: 70px;
        margin-top: -16px;
        display: block;
    }

    .a-nav-brand-nosearch {
        background-image: $BG-Logo-Inverse;
        background-repeat: no-repeat;;
        width: 270px;
        height: 70px;
        margin-top: 0;
        display: block;
    }
}

@include media-breakpoint-down("md") {
    .o-header-new {
        .a-nav-brand {
            width: 200px;
            height: 45px;
            margin-top: 5px;
        }

        .a-nav-brand-nosearch {
            width: 200px;
            height: 45px;
            margin-top: 5px;
        }
    }
}

@include media-breakpoint-down("sm") {
    .o-header-new {
        .a-nav-brand {
            width: 200px;
            height: 45px;
            margin-top: 5px;
        }

        .a-nav-brand-nosearch {
            width: 200px;
            height: 45px;
            margin-top: 5px;
        }
    }
}

//aak landingpage

.o-header-aak {
    background-color: $c-white;
    height: 90px;
    box-shadow: 0 0 7px -1px rgba(48, 48, 48, 0.3);

    .a-nav-brand {
        background-image: url('/images/system/aak-logo.png');
        background-repeat: no-repeat;
        background-size: 100%;
        display: block;
        width: 250px;
        height: 70px;
        margin: 15px 0 0px ;
    }
}

@include media-breakpoint-down("md") {
    .o-header-aak {
        .a-nav-brand {
            background-size: 70%;
            margin: 35px 0px -15px;
        }
    }
}

@include media-breakpoint-down("sm") {
    .o-header-aak {
        .a-nav-brand {
            background-size: 70%;
            margin: 35px 0px -15px;
        }
    }
}

// END Style AAK Header

.a-slider-img {
    max-width: 350px;
}

.a-trust-element {
    margin: 0px 22px 0px;
    opacity: 0.8;
}

.a-box-img {
    width: 150px;
    height: auto;
}

.a-box-img-small {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
}

.a-box-img-full {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.a-img-steps {
    width: 70%;
}

.a-image-ekomi {
    width: 55px;
}

.a-symbol-ekomistar {
    width: 15px;
    margin-left: -3px;

    &.a-symbol-ekomistar-big {
        width: 25px;
    }
}

.a-brand-element {
    margin: 10px 35px;
    height: 30px;
}

.a-awards {
    padding-right: 40px;
    padding-bottom: 20px;
    max-height: 100px;
}

.a-img-tv {

    background-image: url(#{$url-img}tv-video-xl.png);
    background-repeat: no-repeat;
    width: 300px;
    height: 200px;
    cursor: pointer;
    display: block;
    background-position: -118px 5px;
}

.a-img-tv:hover {
    background-image: url(#{$url-img}tv-video-xl-hover.png);
}

//.a-img-responsive { // need to be refactored
// margin: 0 auto;
// display: block;
//}

.img-responsive {
    width: 100%;
    height: auto;
}

.a-arch {
    background-repeat: no-repeat;
    width: 247px;
    height: 60px;
    position: absolute;
    z-index: -10;

    &.a-arch-left {
        background-image: url(#{$url-img}bogen.png);
        left: 153px;
        top: 15px;
    }

    &.a-arch-middle {
        background-image: url(#{$url-img}bogen.png);
        left: 150px;
        bottom: 85px;
        @include transform-rotate(180deg);

    }

    &.a-arch-right {
        background-image: url(#{$url-img}bogen.png);
        right: 153px;
        top: 15px;
    }
}

.a-responsive-video {
    position: relative;
    padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
    padding-top: 0px;
    height: 0;
    overflow: hidden;
    @include box-shadow(0, 0px, 7px, -1px rgba(48, 48, 48, 0.3));

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@include media-breakpoint-down("lg") {
    .a-arch {
        &.a-arch-left {
            left: 100px;
            top: 15px;
        }

        &.a-arch-middle {
            left: 100px;
            bottom: 85px;

        }

        &.a-arch-right {
            right: 100px;
            top: 15px;
        }
    }
    .a-brand-element {
        margin: 10px 75px;
    }
}

@include media-breakpoint-down("md") {
    .a-nav-brand {
        width: 270/100*95px;
        height: 50/100*95px;
    }
    .a-trust-element {
        margin: 0px 15px 0px;
    }
    .a-image-ekomi {
        float: none;
        margin-bottom: 10px;
    }
    .a-brand-element {
        margin: 10px 45px;
    }
    .a-arch {
        &.a-arch-left {
            left: 215px;
            top: 75px;
        }

        &.a-arch-middle {
            left: 100px;
        }

        &.a-arch-right {
            right: 215px;
            top: 75px;
        }
    }
}

@include media-breakpoint-down("sm") {
    .a-nav-brand {
        width: 270/100*90px;
        height: 50/100*90px;
        margin-top: 5px;
    }
    .a-awards {
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .a-trust-element {
        margin: 0px 10px 0px;
    }
    .a-slider-img {
        max-width: 100%;
        float: none !important;
    }
}

@include media-breakpoint-up("xl") {
    .a-trust-element {
        margin: 0px 37px 0px;
    }
    .a-brand-element {
        margin: 10px 95px;
    }
}

.a-rating-image {
    position: absolute;
    max-width: 60px;
    top: -20px;
    left: -55px;
}

.a-media-max-width-sm {
    max-width: 700px;
}

.a-media-max-width-md {
    max-width: 800px;
}
