//@Author: Raffael Pace <mBook>
//@Date:   2017-20-02T12:15:07+01:00
//@Email:  raffael.pace@kautionsfrei.de
//@Last modified by:   mBook
//@Last modified time: 2017-20-02T12:15:07+01:00

.m-list {
	color: $c-text-second;
	&.m-list-footer{
 		margin-bottom: 40px;
  		margin-left: 15px;
		font-size: $fs-s;
		color: #d1d0ce;
	}

}