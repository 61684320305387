// @Author: Michael Neumair <mBook>
// @Date:   2016-09-26T12:45:38+02:00
// @Email:  7q7w7e7r@gmail.com
// @Last modified by:   mBook
// @Last modified time: 2016-09-26T12:56:23+02:00


.a-error-input {
    color: $c-error;
    font-family: $ff-first;
}
