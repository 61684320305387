//@Author: Raffael Pace <mBook>
//@Date:   2017-24-01T12:15:12+01:00
//@Email:  raffael.pace@kautionsfrei.de
//@Last modified by:   mBook
//@Last modified time: 2017-24-01T12:15:12+01:00


.m-pagination {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	border-radius: 5px;
	&.m-pagination-center{
		-webkit-box-pack: center!important;
		-webkit-justify-content: center!important;
		-ms-flex-pack: center!important;
		justify-content: center!important;	padding-left: 0;
	   }
	li{
		margin-left: 0;
	}
}

