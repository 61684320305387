//@Author: Raffael Pace <mBook>
//@Date:   2017-24-01T12:15:12+01:00
//@Email:  raffael.pace@kautionsfrei.de
//@Last modified by:   mBook
//@Last modified time: 2017-24-01T12:15:12+01:00

//mobile navbar

.a-navbar-mobile-trigger {
    color: $c-main;
    background: $c-white;
    padding: 10px 14px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: inline-block;
    cursor: pointer;
    margin-top: 36px;
    float: right;
    font-weight: 700;
    span {
        color: $c-main;
    }
}

@include media-breakpoint-up("lg") {
    #a-navbar-mobile-trigger {
        display: none;
    }
}
