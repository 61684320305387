//@Author: Raffael Pace <mBook>
//@Date:   2017-15-02T12:11:07+01:00
//@Email:  raffael.pace@kautionsfrei.de
//@Last modified by:   mBook
//@Last modified time: 2017-15-02T12:11:07+01:00

.o-pagination{
	width: 100%;
	background: $c-bg-light;
    border: 1px solid $c-bg-dark;
    border-radius: 5px;
}