//@Author: Raffael Pace <mBook>
//@Date:   2017-20-02T12:15:07+01:00
//@Email:  raffael.pace@kautionsfrei.de
//@Last modified by:   mBook
//@Last modified time: 2017-20-02T12:15:07+01:00

.m-ekomi-user-valuation{
    text-align: center;
    display: inherit;
    display: inherit;
}

.m-file-entry-icon{
  color: $c-main;
}

.m-file-entry {
    a {
        color:$c-cross-reference;
        text-decoration: underline;
    }
}

.m-brands-img {
  .a-paragraph {
    color: $c-white;
    text-align: left;
  }
}