//@Author: Raffael Pace <mBook>
//@Date:   2017-20-02T12:15:07+01:00
//@Email:  raffael.pace@kautionsfrei.de
//@Last modified by:   mBook
//@Last modified time: 2017-20-02T12:15:07+01:00

.m-accordion {
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 3px;
    background: $c-white;
    color: $white;
    border-top: 1px solid #b7b7b7;
    margin-top: 30px;

    * {
        box-sizing: border-box;
    }

    .m-box {
        margin: 10px 0px;
    }
}

.m-accordion-header {
    width: 100%;
    padding: 15px;
    padding-left: 10px;
    padding-bottom: 10px;
    display: inline-block;
    border-bottom: 1px solid $c-bg-dark;
    transition: all linear 0.15s;
    color: $c-text-main;
    font-size: $fs-m;
    font-weight: $fw-light;

    i {
        font-size: $fs-xxl;
        margin-top: -9px;
    }

    &.active {
        text-decoration: none;
        height: auto;
        color: $c-text-main;

        &::after {
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
            transform: rotate(180deg);
            transition: transform 600ms ease;
        }
    }

    &:hover {
        //background: none;
        text-decoration: none;
        color: $c-main;
    }

    &::after {
        content: "\f107";
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        float: right;
        color: $c-text-main;
        transition: transform 600ms ease;
        margin-top: -10px;
        font-size: $fs-heading-s;
    }
}

.m-accordion-content {
    padding: 30px 40px;
    display: none;
}

.m-accordion-section {
    &:last-child {
        .m-accordion-header {
            border-bottom: none;
        }
    }
}

@include media-breakpoint-down("md") {
    .m-accordion-header {
        padding-right: 40px;

        &::after {
            margin-right: -22px;
        }
    }
}

@include media-breakpoint-down("sm") {
    .m-accordion-header {
        padding-right: 50px;

        &::after {
            margin-right: -35px;
        }
    }
}


// AAK Landingpage
.o-main-aak {
    .o-accordion {
        .m-accordion {
            border-radius: 0px;
            border-top: none;
            margin-top: 0;

            .m-accordion-header {
                padding: 20px 10px;
                border-bottom: 1px solid $c-black;
                color: $c-text-main;
                font-size: $fs-xxl;
                font-weight: $fw-light;
                line-height: $lh-flat;

                i {
                    font-size: $fs-xl;
                    margin-top: -9px;
                }

                &::after {
                    margin-top: 0px;
                    font-size: $fs-xl;
                }

                &.active {
                    color: $c-main;
                }
            }

            .m-accordion-content {
                padding: 30px 40px 30px 10px;
            }
        }
    }
}

@include media-breakpoint-down("md") {
    .o-main-aak {
        .o-accordion {
            .m-accordion {
                .m-accordion-header {
                    font-size: $fs-xl;
                    padding-right: 10px;

                    &::after {
                        margin-right: 0px;
                        margin-top: -5px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down("sm") {
    .o-main-aak {
        .o-accordion {
            .m-accordion {
                .m-accordion-header {
                    font-size: $fs-m;
                    padding-right: 10px;

                    &::after {
                        margin-right: 0px;
                        margin-top: -5px;
                    }
                }
            }
        }
    }
}
