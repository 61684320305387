// just a divider for the preview

.a-lines {

}

.a-lines-divider {
    border: 1px solid #eee;
    margin: 15px 0px;
    min-width: 100%;
    float: left;
}
