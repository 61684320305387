.oa-sidebar-kfx-awards {
    margin-left: 18%;
}


@media (max-width: 978px) {
    .o-trust-awards {
        border-top: 2.5px dashed $c-main;
    }
    .m-trust-box {
        display: none;
    }
    .oa-sidebar-kfx-awards {
        img {
            margin-right: 0;
        }
    }
}


@media (min-width: 979px) {
    .m-trust-box {
        display: block;

        img {
            transform: scale(.7);
            transition: transform .6s;
            max-height: 130px;

            &:hover {
                cursor: zoom-in;
                cursor: -moz-zoom-in;
                cursor: -webkit-zoom-in;
                transform: scale(.9);
            }
        }
    }
}


/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 20; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
    width: 20%;
    max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
.caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, .caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}